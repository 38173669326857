import { ProductTile } from "@/components/product/tile";
import { Skeleton } from "@/components/ui/components/skeleton";
import { Typography } from "@/components/ui/components/typography";

export type CartItem = {
	id: string;
	name: string;
	price: string;
	discountedPrice: string;
	amount: number;
	attributes: Record<string, string>;
	img: {
		src?: string;
		alt: string;
	};
	isOnStock?: boolean;
};

export const CartProductTileSkeleton = () => {
	return (
		<ProductTile>
			<ProductTile.Panel className="asio-product-tile__img-panel">
				<Skeleton className="w-[100px] h-[20px] rounded" />
			</ProductTile.Panel>
			<ProductTile.Panel className="asio-product-tile__details-panel !text-gray-500">
				<Skeleton className="w-[100px] h-[20px] rounded" />
				<div className="flex flex-col flex-end mt-2 space-y-3">
					<Skeleton className="w-[100px] h-[20px] rounded" />
					<Skeleton className="w-[100px] h-[20px] rounded" />
					{new Array().fill(0, 7).map((attribute, index) => (
						<Skeleton
							key={`${attribute}-${index}`}
							className="w-[100px] h-[20px] rounded"
						/>
					))}
				</div>
			</ProductTile.Panel>
		</ProductTile>
	);
};
