"use client";

import { useCart } from "@/commercetools/hooks/use-cart";
import { Params } from "@/commercetools/types/next.types";
import { Button } from "@/components/ui/components/button";
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetFooter,
	SheetHeader,
	SheetTitle,
} from "@/components/ui/components/sheet";
import { Typography } from "@/components/ui/components/typography";
import { useToast } from "@/components/ui/hooks/use-toast";
import { XIcon } from "lucide-react";
import Link from "next/link";
import { useParams, useRouter } from "next/navigation";
import {
	CartItem,
	CartProductTile,
} from "@/components/cart/templates/cart-product-tile";
import { Divider } from "@/components/ui/components/divider";
import { useTransition } from "react";
import { useCartDrawerContext } from "@/app/providers";
import { CartProductTileSkeleton } from "../templates/cart-product-tile-skeleton";

export function CartDrawer<T>({
	count,
	removeItemFromCartAction,
}: {
	count: number;
	items: CartItem[];
	removeItemFromCartAction: (payload: T, params: Params) => Promise<void>;
}) {
	const [{ isOpen, items, isAddingNewItem }, { onToggle }] =
		useCartDrawerContext();
	const params = useParams();
	const { toast } = useToast();
	const { createRemoveCartItemPayload } = useCart();
	const [isPending, startTransition] = useTransition();
	const { refresh } = useRouter();

	const handleRemoveItem = async (item: CartItem) => {
		try {
			await removeItemFromCartAction(
				createRemoveCartItemPayload(item.id) as T,
				params
			);

			startTransition(() => {
				refresh();
			});
		} catch (error) {
			toast({
				variant: "destructive",
				title: "An error ocurred",
				description: "An error occurred while trying to remove item from cart",
			});
		}
	};

	return (
		<Sheet defaultOpen={isOpen} onOpenChange={() => onToggle()} open={isOpen}>
			<SheetContent
				showOverlay={false}
				showCloseButton={false}
				className="asio-sheet-content"
			>
				<div className="flex flex-col space-y-4">
					<SheetHeader>
						<div className="flex flex-col gap-2">
							<SheetTitle>Shopping Cart ({count})</SheetTitle>
							<Divider />
						</div>
						<SheetClose className="asio-sheet-close">
							<XIcon className="h-6 w-6" />
						</SheetClose>
					</SheetHeader>
					<div className="flex flex-col space-y-4">
						{isAddingNewItem
							? items
									?.map((item, index) => (
										<CartProductTile
											key={index}
											item={item}
											onRemoveItem={handleRemoveItem}
											isPending={isPending}
										/>
									))
									.concat(<CartProductTileSkeleton key="skeleton" />)
							: items?.map((item, index) => (
									<CartProductTile
										key={index}
										item={item}
										onRemoveItem={handleRemoveItem}
										isPending={isPending}
									/>
							  ))}
					</div>
				</div>
				<SheetFooter>
					<Typography.Text>Apply promo codes in the cart</Typography.Text>
					<Link href="/cart">
						<SheetClose asChild>
							<Button
								type="submit"
								variant="primary"
								className="asio-place-order__button md:w-full"
							>
								<span>View Cart</span>
							</Button>
						</SheetClose>
					</Link>
				</SheetFooter>
			</SheetContent>
		</Sheet>
	);
}
