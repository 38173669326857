import {
	RemoveCartItemPayload,
	UpdateCartItemPayload,
} from "@commercetools/frontend-composable-commerce/lib/types/payloads/CartPayloads";

export function useCart() {
	const createRemoveCartItemPayload = (
		itemId: string
	): RemoveCartItemPayload => {
		return {
			lineItem: {
				id: itemId,
			},
		};
	};

	const createUpdateCartItemPayload = (
		itemId: string,
		count: number
	): UpdateCartItemPayload => {
		return {
			lineItem: {
				id: itemId,
				count,
			},
		};
	};

	return { createRemoveCartItemPayload, createUpdateCartItemPayload };
}
