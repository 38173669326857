"use client";

import Script from "next/script";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

function pageview(gaId: string, url: string) {
	window.gtag("config", gaId, {
		page_path: url,
	});
}

export function GoogleAnalytics({ gaId }: { gaId: string }) {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	useEffect(() => {
		const url = pathname + searchParams.toString();
		pageview(gaId, url);
	}, [pathname, searchParams, gaId]);

	return (
		<>
			<Script
				strategy="afterInteractive"
				src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
			/>
			<Script
				id="google-analytics"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('consent', 'default', {
                    'analytics_storage': 'denied'
                });
                
                gtag('config', '${gaId}', {
                    page_path: window.location.pathname,
                });
                `,
				}}
			/>
		</>
	);
}
